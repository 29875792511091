/* eslint-disable */
// @ts-nocheck
import axios, { InternalAxiosRequestConfig } from "axios";
import {convertKeysToCamelCase, snakeToCamel} from "@/helpers/snake-to-camel";
import {API_URL_DEV, API_URL_LOCAL, API_URL_PROD, NODE_ENV} from "@/config";
import {fetchRefreshToken} from "@/modules/auth/services/auth.services";

// const API_URL = NODE_ENV === "production" ? API_URL_PROD : API_URL_DEV;
export const API_URL = API_URL_PROD;

const $api = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (localStorage.getItem("accessToken")) {
      config.headers.Authorization = `Bearer_${localStorage.getItem("accessToken")}`;
    }
    return config;
  }
);

$api.interceptors.response.use(function (response) {
    if (response.data && typeof response.data === 'object') {
        response.data = convertKeysToCamelCase(response.data);
    }
    return response;
}, async function (error) {
  {
    const originalConfig = error.config;

    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const response = await fetchRefreshToken(
          localStorage.getItem("refreshToken")
        );

        const accessToken = response.data.access_token;
        const refreshToken = response.data.refresh_token;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        originalConfig.headers = {
          ...originalConfig.headers,
          Authorization: `Bearer_${accessToken}`,
        };

        return $api(originalConfig);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
});

export default $api;
